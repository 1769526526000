import styled from 'styled-components';
import ReactModal from 'react-modal';

export const Container = styled(ReactModal)`
    width: 100vw;
    height: 100%;
    overflow: hidden;
    cursor: initial;
    outline: none;
`;
