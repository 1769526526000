import styled, { css } from 'styled-components';
import { color, mediaBreakpointUp, scrollbar, scrollbarImg, vw } from '@/style/mixins';
import Modal from '@/components/common/Modal/Modal';
import { Colors } from '@/style/colors';
import { Container as ServicesForm } from '@/components/ui/BaseFormContentLeft/BaseFormContentLeft.styled';
import { Container as Image } from '@/components/common/Image/Image.styled';

export const ModalComponent = styled(Modal)<{ image?: boolean }>`
    margin-left: auto;
    ${({ image }) =>
        image
            ? css`
                  margin-right: auto;
              `
            : ''}
    width: auto;
`;

export const ModalInner = styled.div<{ image?: boolean }>`
    flex-shrink: 1;
    overflow: auto;
    overflow-x: hidden;

    ${({ image }) =>
        image
            ? css`
                  ${scrollbarImg};
                  height: 70vh;
                  background: transparent;

                  img {
                      object-fit: contain;
                      display: flex;
                      margin: auto;
                  }

                  ${mediaBreakpointUp('xl')} {
                      height: 80vh;
                  }

                  ${mediaBreakpointUp('fhd')} {
                      height: 80%;
                  }
              `
            : css`
                  height: 100%;
                  ${scrollbar};
              `};
`;

export const Container = styled.div<{ bgColor?: Colors; image?: boolean; isWide?: boolean }>`
    position: relative;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    ${({ image }) => (image ? 'width: 90vw;' : 'width: 100vw;')};
    height: 100%;
    background-color: ${({ bgColor, image }) => (image ? 'transparent' : bgColor ? color(bgColor) : color('white'))};

    ${mediaBreakpointUp('xl')} {
        ${({ image, isWide }) => (image ? 'width: 40vw;' : `width: ${vw(isWide ? 1350 : 1150)};`)};
    }

    ${ServicesForm} {
        position: initial;
    }

    ${Image} {
        width: 100%;
        height: 100%;
        background: transparent;
        margin-right: 0 !important;
        img {
            border-radius: 0;
            object-fit: contain !important;
            background: transparent;
        }
    }

    ${mediaBreakpointUp('xxl')} {
        ${({ image }) => (image ? 'width: 46vw;' : 'width: 66vw;')};
    }

    ${mediaBreakpointUp('fhd')} {
        ${({ image, isWide }) => (image ? 'width: 40vw;' : `width: ${vw(isWide ? 1200 : 1000)};`)};
    }

    ${ModalInner} {
        position: relative;
    }
`;
