import React from 'react';
import { Bg, Container } from '@/components/ui/BaseFormCommon/BaseFormCommon.styled';
import { BaseFormCommonI, BaseUI } from '@/interfaces';

interface BaseFormCommonProps extends BaseFormCommonI, BaseUI {
    id?: string;
}

const BaseFormCommon = React.forwardRef<HTMLDivElement, BaseFormCommonProps>(
    ({ picture, topOffset, bottomOffset, outsideTOffset, outsideBOffset, sideOffset = true, children, bgColor, className, id }, ref) => {
        return (
            <Container
                ref={ref}
                className={className}
                topOffset={topOffset}
                bottomOffset={bottomOffset}
                outsideTOffset={outsideTOffset}
                outsideBOffset={outsideBOffset}
                sideOffset={sideOffset}
                bgColor={bgColor}
                id={id}
            >
                {picture && <Bg {...picture} />}
                {children}
            </Container>
        );
    }
);

BaseFormCommon.displayName = 'BaseFormCommon';

export default BaseFormCommon;
