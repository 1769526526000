import styled from 'styled-components';
import { font, offset, allBreakpointValue, mediaBreakpointUp, vw } from '@/style/mixins';
import { Container as CheckboxConfirm } from '@/components/ui/CheckboxConfirm/CheckboxConfirm.styled';
import { Container as InputFile } from '@/components/ui/InputFile/InputFile.styled';
import BaseFormCommon from '@/components/ui/BaseFormCommon/BaseFormCommon';

export const Container = styled(BaseFormCommon)`
    ${mediaBreakpointUp('xl')} {
        display: flex;
    }
`;

export const ContentForm = styled.div`
    position: relative;

    ${allBreakpointValue('padding-right', 127, 100, 90)};

    ${mediaBreakpointUp('md')} {
        width: ${(414 / (768 - 80)) * 100}%;
    }

    ${mediaBreakpointUp('xl')} {
        width: calc(50% - 5px);
    }

    h2 {
        ${font('h4-b')};
    }

    & > p {
        ${font('text2-l')};
        ${offset('xs', 'margin-top')};
    }

    span {
        ${font('text1-l')};
    }
`;

export const Form = styled.form`
    position: relative;
    width: 100%;
    flex-shrink: 0;
    margin-top: 34px;
    transition: height 0.3s ease;

    ${mediaBreakpointUp('md')} {
        margin-top: ${vw(48, 768)};
    }

    ${mediaBreakpointUp('xl')} {
        width: calc(50% - 5px);
        margin-left: auto;
        margin-top: 0;
    }

    .itemForm {
        &:not(:first-child) {
            margin-top: 10px;
        }

        & > p {
            margin-top: 8px;
        }
    }

    ${InputFile} {
        margin-top: 20px;
    }

    ${CheckboxConfirm} {
        ${offset('l', 'margin-top')};
    }
`;
