import styled, { css } from 'styled-components';
import { color, font, offset, mediaBreakpointDown, mediaBreakpointUp, vw } from '@/style/mixins';

export const Container = styled.div<{ borderColored?: boolean; image?: boolean }>`
    position: relative;
    display: flex;
    justify-content: space-between;
    ${offset('xl', 'padding')};

    ${mediaBreakpointDown('md')} {
        padding: 32px 20px;
    }

    ${({ image }) =>
        image
            ? css`
                  background-color: transparent;
              `
            : css`
                  background-color: ${color('white')};
              `};

    border-bottom: solid 1px
        ${({ borderColored, theme, image }) => (image ? 'transparent' : borderColored ? color(theme.main.background) : color('black', 0.1))};
`;

export const TextWrapper = styled.div`
    ${offset('m', 'margin-right')};
    width: ${(925 / (1200 - 60 * 2)) * 100}%;

    ${mediaBreakpointUp('fhd')} {
        width: ${vw(689)};
    }
`;

export const Title = styled.h2`
    ${font('h4-b')};
`;

export const Description = styled.p`
    ${offset('xs', 'margin-top')};
    ${font('text1-l')};
`;

export const CloseBtn = styled.button<{ image?: boolean }>`
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 50px;
    height: 24px;
    background-color: transparent;
    cursor: pointer;
    border: none;

    ${mediaBreakpointUp('fhd')} {
        width: ${vw(50)};
        height: ${vw(24)};
    }

    svg {
        width: 24px;
        height: 24px;
        ${({ image }) =>
            image
                ? css`
                      stroke: ${color('white')};
                  `
                : css`
                      stroke: ${color('black')};
                  `};
        pointer-events: none;

        ${mediaBreakpointUp('fhd')} {
            width: ${vw(24)};
            height: ${vw(24)};
        }
    }
`;
