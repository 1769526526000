import React from 'react';
import ReactModal from 'react-modal';
import { Container } from './Modal.styled';
import { color } from '@/style/mixins';

interface CustomModalProps {
    className?: string;
    isOpen: boolean;
    onRequestClose?: () => void;
    children: React.ReactNode;
    isImage?: boolean;
}

const MODAL_OPTIONS = {
    closeTimeoutMS: 300,
    style: {
        overlay: {
            display: 'flex',
            backgroundColor: color('black', 0.7),
            zIndex: 10000
        }
    }
};

ReactModal.setAppElement('#__next');

const Modal: React.FC<CustomModalProps> = ({ isOpen, onRequestClose, isImage, className = 'modal-content', children }) => {
    if (isImage) {
        // @ts-ignore
        MODAL_OPTIONS.style['content'] = { margin: 'auto' };
    }
    return (
        <Container className={className} {...MODAL_OPTIONS} onRequestClose={onRequestClose} isOpen={isOpen}>
            {children}
        </Container>
    );
};

export default Modal;
